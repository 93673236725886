import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
class DataDevice {
}
DataDevice.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.DEVICES },
        text: i18n.t('breadcrumbs.devices'),
    },
    {
        text: i18n.t('breadcrumbs.device'),
        disabled: true
    }
];
DataDevice.BreadcrumbsWearable = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.DEVICES },
        text: i18n.t('breadcrumbs.devices'),
    },
    {
        text: i18n.t('breadcrumbs.wearable'),
        disabled: true
    }
];
DataDevice.BreadcrumbsESim = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.DEVICES },
        text: i18n.t('breadcrumbs.devices'),
    },
    {
        text: i18n.t('breadcrumbs.e_sim'),
        disabled: true
    }
];
DataDevice.TableHeadersDevice = [
    {
        text: "Brand",
        value: "brand",
        width: '15%'
    },
    {
        text: "Model name",
        value: "model_name",
        width: '25%'
    },
    {
        text: "Production emission (KG)",
        value: "production_emission"
    },
    {
        text: "Total use (KG)",
        value: "total_use"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '15%',
        sortable: false
    }
];
DataDevice.TableHeadersWearable = [
    {
        text: "Brand",
        value: "brand",
        width: '15%'
    },
    {
        text: "Name",
        value: "name",
        width: '25%'
    },
    {
        text: "Create at",
        value: "create_at",
        sortable: false
    },
    {
        text: "Production emission (KG)",
        value: "product_emission"
    },
    {
        text: "Total use (KG)",
        value: "total_use"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '15%',
        sortable: false
    }
];
DataDevice.TableHeadersEsim = [
    {
        text: "Brand",
        value: "brand",
        width: '15%'
    },
    {
        text: "Name",
        value: "name"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '15%',
        sortable: false
    }
];
export default DataDevice;

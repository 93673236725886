import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataSettings {
}
DataSettings.SettingsLanguageBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: i18n.t('breadcrumbs.language'),
        disabled: true
    }
];
DataSettings.SettingsGreenConfigBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: i18n.t('breadcrumbs.green-config'),
        disabled: true
    }
];
DataSettings.SettingsApplicationBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: i18n.t('breadcrumbs.application'),
        disabled: true
    }
];
DataSettings.TableHeadersGreenConfig = [
    {
        text: "Key",
        value: "key"
    },
    {
        text: "Type",
        value: "type",
        width: '25%'
    },
    {
        text: "Value",
        value: "value"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '10%',
        sortable: false
    }
];
DataSettings.LanguageList = [
    {
        title: 'English',
        lang: 'eng',
        disabled: false
    },
    {
        title: 'Dutch',
        lang: 'du',
        disabled: true
    },
    {
        title: 'French',
        lang: 'fr',
        disabled: true
    }
];

import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataEmailNotifications {
}
DataEmailNotifications.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: i18n.t('breadcrumbs.email_notifications'),
        disabled: true
    },
];
DataEmailNotifications.BreadcrumbsEdit = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        to: { name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS },
        text: i18n.t('breadcrumbs.email_notifications'),
    },
    {
        text: i18n.t('breadcrumbs.edit'),
        disabled: true
    }
];
DataEmailNotifications.BreadcrumbsCreate = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        to: { name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS },
        text: i18n.t('breadcrumbs.email_notifications'),
    },
    {
        text: i18n.t('breadcrumbs.create'),
        disabled: true
    },
];
DataEmailNotifications.TableHeaders = [
    {
        text: i18n.t('table_headers.updated'),
        value: "update",
        width: "15%",
        sortable: false
    },
    {
        text: i18n.t('table_headers.template_name'),
        value: "template_name"
    },
    {
        text: i18n.t('table_headers.action'),
        value: "action",
        width: "15%",
        sortable: false
    }
];

import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataSlider {
}
DataSlider.SliderListBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.SLIDER },
        text: i18n.t('breadcrumbs.slider'),
    },
    {
        text: i18n.t('breadcrumbs.slides'),
        disabled: true
    }
];
DataSlider.SliderDisplayBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.SLIDER },
        text: i18n.t('breadcrumbs.slider'),
    },
    {
        text: i18n.t('breadcrumbs.display_settings'),
        disabled: true
    }
];
DataSlider.SliderListCreateBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.SLIDER },
        text: i18n.t('breadcrumbs.slider'),
    },
    {
        text: i18n.t('breadcrumbs.slides'),
        to: { name: RouteNames.SLIDER_SLIDES },
    },
    {
        text: i18n.t('breadcrumbs.create'),
        disabled: true
    }
];
DataSlider.SliderListEditBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.SLIDER },
        text: i18n.t('breadcrumbs.slider'),
    },
    {
        text: i18n.t('breadcrumbs.slides'),
        to: { name: RouteNames.SLIDER_SLIDES }
    },
    {
        text: i18n.t('breadcrumbs.edit'),
        disabled: true
    }
];
DataSlider.SliderListTableHeaders = [
    {
        text: i18n.t('table_headers.date'),
        value: "create_at",
        width: '20%'
    },
    {
        text: i18n.t('table_headers.slide_title'),
        value: "name",
        width: '65%'
    },
    {
        text: i18n.t('table_headers.action'),
        value: "action"
    }
];
DataSlider.SliderDisplayTableHeaders = [
    {
        text: i18n.t('table_headers.sorting'),
        value: "slider_sort",
        width: '12%'
    },
    {
        text: i18n.t('table_headers.created_at'),
        value: "create_at",
        width: '15%'
    },
    {
        text: i18n.t('table_headers.source'),
        value: "from",
        width: '12%'
    },
    {
        text: i18n.t('table_headers.title'),
        value: "name",
    },
    {
        text: i18n.t('table_headers.action'),
        value: "action",
        width: '10%',
        sortable: false
    }
];

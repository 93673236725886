import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataVariables {
}
DataVariables.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms')
    },
    {
        text: i18n.t('breadcrumbs.variables'),
        disabled: true
    },
];
DataVariables.TableHeaders = [
    {
        text: i18n.t('breadcrumbs.variable_name'),
        value: "name"
    },
    {
        text: i18n.t('breadcrumbs.value'),
        value: "value"
    },
    {
        text: i18n.t('breadcrumbs.created_at'),
        sortable: false,
        value: "create_at"
    },
    {
        text: i18n.t('breadcrumbs.update_at'),
        sortable: false,
        value: "update_at"
    },
    {
        text: i18n.t('breadcrumbs.action'),
        width: '15%',
        sortable: false,
        value: "action"
    }
];

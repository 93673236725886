import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataProjects {
}
DataProjects.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms')
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.projects')
    }
];
DataProjects.TableHeaders = [
    {
        text: i18n.t('table_headers.project'),
        value: "project"
    },
    {
        text: i18n.t('table_headers.projectType'),
        value: "projectType"
    },
    {
        text: i18n.t('table_headers.location'),
        value: "location"
    },
    {
        text: i18n.t('table_headers.cost'),
        value: "cost"
    },
    {
        text: i18n.t('table_headers.action'),
        width: '10%',
        value: "actions",
        sortable: false
    }
];

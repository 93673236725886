import axios from "axios";
class ApiVariables {
    static async GetVariables(session_uuid) {
        try {
            const result = await axios.get("/api/cms/variables/get-list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get files");
        }
    }
    static async CreateVariable(session_uuid, item) {
        try {
            const result = await axios.post("/api/cms/variables/create", {
                name: item.name,
                value: item.value,
                publish: item.publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeVariable(session_uuid, item) {
        try {
            const result = await axios.post("/api/cms/variables/edit", {
                name: item.name,
                value: item.value,
                publish: item.publish,
                uuid: item.uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeVariablePublish(session_uuid, publish, uuid) {
        try {
            const result = await axios.post("/api/cms/variables/change-publish", {
                publish: publish,
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async DeleteVariable(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/variables/delete", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}
export default ApiVariables;

import { __decorate } from "tslib";
import { Vue } from "vue-property-decorator";
import Component from 'vue-class-component';
import dayjs from "dayjs";
import { RouteNames } from "@/router/routes";
import EUserEnum from "@/struct/user/EUserEnum";
import { Getter } from "vuex-class";
import UtilGeneral from "@/util/Common/UtilGeneral";
let ListMixin = class ListMixin extends Vue {
    constructor() {
        super(...arguments);
        this.isTableLoading = false;
        this.isSavingLoading = false;
        this.search = "";
        this.RouteNameList = RouteNames;
        //TODO Перенести в FormValidation
        this.decimalRules = [
            (v) => /^\d{0,6}(?:\.\d{1,2})?$/.test(v) || "Value format 00.00"
        ];
    }
    CanAdminAccess() {
        return (this.GetCurrentSessionUser != undefined && (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin));
    }
    formatDate(item, format = "DD.MM.YYYY HH:mm") {
        return item ? dayjs(item).format(format) : '';
    }
    parseItemsIntoEnum(obj = {}, isSimpleEnum = true) {
        return Object.entries(obj).filter(e => isSimpleEnum ? !isNaN(Number(e[0])) : true).map(([key, value]) => ({
            key: isSimpleEnum ? parseInt(key) : key, value
        }));
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], ListMixin.prototype, "GetCurrentSessionUser", void 0);
ListMixin = __decorate([
    Component
], ListMixin);
export default ListMixin;

import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
class DataCustomerInfo {
}
DataCustomerInfo.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        text: i18n.t('breadcrumbs.customer'),
        disabled: true
    }
];
DataCustomerInfo.ViewBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        text: i18n.t('breadcrumbs.customer'),
        to: { name: RouteNames.CUSTOMER_LIST },
    },
    {
        text: i18n.t('breadcrumbs.customerView'),
        disabled: true
    }
];
DataCustomerInfo.TableHeaders = [
    {
        text: i18n.t("table_headers.name"),
        value: "display_name"
    },
    {
        text: i18n.t("table_headers.status"),
        value: "status",
    },
    {
        text: i18n.t("table_headers.type"),
        value: "type",
    },
    {
        text: i18n.t("table_headers.language"),
        value: "language",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "actions",
        width: "10%",
        sortable: false
    }
];
export default DataCustomerInfo;

export var EOrderEnum;
(function (EOrderEnum) {
    let OrderStatus;
    (function (OrderStatus) {
        OrderStatus["New"] = "New";
        OrderStatus["InProcess"] = "In Process";
        OrderStatus["InDelivery"] = "In Delivery";
        OrderStatus["Delivered"] = "Delivered";
        OrderStatus["PartiallyFinished"] = "Partially Finished";
        OrderStatus["Cancelled"] = "Cancelled";
        OrderStatus["NotFinished"] = "Not Finished";
        OrderStatus["Finished"] = "Finished";
    })(OrderStatus = EOrderEnum.OrderStatus || (EOrderEnum.OrderStatus = {}));
    let CustomerType;
    (function (CustomerType) {
        CustomerType[CustomerType["Personal"] = 0] = "Personal";
        CustomerType[CustomerType["Business"] = 1] = "Business";
    })(CustomerType = EOrderEnum.CustomerType || (EOrderEnum.CustomerType = {}));
    let MarkType;
    (function (MarkType) {
        MarkType["None"] = "None";
        MarkType["Red"] = "Red";
        MarkType["Yellow"] = "Yellow";
        MarkType["Green"] = "Green";
    })(MarkType = EOrderEnum.MarkType || (EOrderEnum.MarkType = {}));
    let PaymentMethod;
    (function (PaymentMethod) {
        PaymentMethod[PaymentMethod["WTransfer"] = 0] = "WTransfer";
        PaymentMethod[PaymentMethod["DDebit"] = 1] = "DDebit";
    })(PaymentMethod = EOrderEnum.PaymentMethod || (EOrderEnum.PaymentMethod = {}));
    let OrderPaymentStatus;
    (function (OrderPaymentStatus) {
        OrderPaymentStatus[OrderPaymentStatus["NotPaid"] = 0] = "NotPaid";
        OrderPaymentStatus[OrderPaymentStatus["Paid"] = 1] = "Paid";
        OrderPaymentStatus[OrderPaymentStatus["Refunded"] = 2] = "Refunded";
        OrderPaymentStatus[OrderPaymentStatus["PartiallyRefunded"] = 3] = "PartiallyRefunded";
    })(OrderPaymentStatus = EOrderEnum.OrderPaymentStatus || (EOrderEnum.OrderPaymentStatus = {}));
    let OrderProductSimStatus;
    (function (OrderProductSimStatus) {
        OrderProductSimStatus[OrderProductSimStatus["NoActive"] = 0] = "NoActive";
        OrderProductSimStatus[OrderProductSimStatus["PendingStartActivation"] = 1] = "PendingStartActivation";
        OrderProductSimStatus[OrderProductSimStatus["PendingWaitingKYC"] = 2] = "PendingWaitingKYC";
        OrderProductSimStatus[OrderProductSimStatus["PendingPaymentForNumber"] = 3] = "PendingPaymentForNumber";
        OrderProductSimStatus[OrderProductSimStatus["PendingRepeatKYC"] = 4] = "PendingRepeatKYC";
        OrderProductSimStatus[OrderProductSimStatus["PendingFromStartedKYC"] = 5] = "PendingFromStartedKYC";
        OrderProductSimStatus[OrderProductSimStatus["PendingFromCompletedKYC"] = 6] = "PendingFromCompletedKYC";
        OrderProductSimStatus[OrderProductSimStatus["AcceptedFromCompletedKYC"] = 7] = "AcceptedFromCompletedKYC";
        OrderProductSimStatus[OrderProductSimStatus["CancelledFailedKYC"] = 8] = "CancelledFailedKYC";
        OrderProductSimStatus[OrderProductSimStatus["CancelledByCustomer"] = 9] = "CancelledByCustomer";
        OrderProductSimStatus[OrderProductSimStatus["New"] = 100] = "New";
        OrderProductSimStatus[OrderProductSimStatus["InProcess"] = 101] = "InProcess";
        OrderProductSimStatus[OrderProductSimStatus["InDelivery"] = 102] = "InDelivery";
    })(OrderProductSimStatus = EOrderEnum.OrderProductSimStatus || (EOrderEnum.OrderProductSimStatus = {}));
    let OrderHistoryType;
    (function (OrderHistoryType) {
        OrderHistoryType[OrderHistoryType["OrderCreate"] = 0] = "OrderCreate";
        OrderHistoryType[OrderHistoryType["OrderStatus"] = 1] = "OrderStatus";
        OrderHistoryType[OrderHistoryType["PaymentStatus"] = 2] = "PaymentStatus";
        OrderHistoryType[OrderHistoryType["ProductStatus"] = 3] = "ProductStatus";
        OrderHistoryType[OrderHistoryType["ChangeValue"] = 4] = "ChangeValue";
    })(OrderHistoryType = EOrderEnum.OrderHistoryType || (EOrderEnum.OrderHistoryType = {}));
})(EOrderEnum || (EOrderEnum = {}));

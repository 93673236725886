import { RouteNames } from "@/router/routes";
import EmailNotifications from "@/views/Settings/EmailNotification/EmailNotifications.vue";
import EmailNotificationsEdit from "@/views/Settings/EmailNotification/EmailNotificationsEdit.vue";
import RouterView from "@/components/common/RouterView.vue";
import GreenConfig from "@/views/Settings/GreenConfig.vue";
import ApplicationSetting from "@/views/Settings/ApplicationSetting.vue";
const settingsRoutes = [
    {
        path: "settings",
        name: RouteNames.SETTINGS,
        component: RouterView,
        redirect: { name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS },
        children: [
            {
                path: "email-notifications",
                name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS,
                component: RouterView,
                redirect: { name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_LIST,
                        component: EmailNotifications,
                    },
                    {
                        path: "create",
                        name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_CREATE,
                        component: EmailNotificationsEdit,
                    },
                    {
                        path: "edit/:uuid",
                        name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_EDIT,
                        component: EmailNotificationsEdit,
                    }
                ]
            },
            {
                path: "config",
                name: RouteNames.SETTINGS_CONFIG,
                component: RouterView,
                redirect: { name: RouteNames.SETTINGS_GREEN_CONFIG },
                children: [
                    {
                        path: "green",
                        name: RouteNames.SETTINGS_GREEN_CONFIG,
                        component: GreenConfig,
                    },
                    {
                        path: "application",
                        name: RouteNames.SETTINGS_APPLICATION,
                        component: ApplicationSetting,
                    }
                ]
            }
        ]
    }
];
export default settingsRoutes;

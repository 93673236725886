export var EDeviceBrand;
(function (EDeviceBrand) {
    EDeviceBrand["Apple"] = "Apple";
    EDeviceBrand["Google"] = "Google";
    EDeviceBrand["Huawei"] = "Huawei";
    EDeviceBrand["Microsoft"] = "Microsoft";
    EDeviceBrand["Motorola"] = "Motorola";
    EDeviceBrand["NUU_Mobile"] = "NUU Mobile";
    EDeviceBrand["Oppo"] = "Oppo";
    EDeviceBrand["Samsung"] = "Samsung";
    EDeviceBrand["Sony"] = "Sony";
    EDeviceBrand["Other"] = "Other";
})(EDeviceBrand || (EDeviceBrand = {}));

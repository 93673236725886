import SliderList from "@/views/Slider/SliderList.vue";
import SliderEdit from "@/views/Slider/SliderEdit.vue";
import SliderDisplay from "@/views/Slider/SliderDisplay.vue";
import { RouteNames } from "@/router/routes";
import RouterView from "@/components/common/RouterView.vue";
const sliderRoutes = [
    {
        path: "slider",
        name: RouteNames.SLIDER,
        component: RouterView,
        redirect: { name: RouteNames.SLIDER_SLIDES },
        children: [
            {
                path: "slides",
                name: RouteNames.SLIDER_SLIDES,
                component: SliderList
            },
            {
                path: "create",
                name: RouteNames.SLIDER_CREATE,
                component: SliderEdit
            },
            {
                path: "edit/:uuid",
                name: RouteNames.SLIDER_EDIT,
                component: SliderEdit
            },
            {
                path: "display-settings",
                name: RouteNames.SLIDER_DISPLAY_SETTINGS,
                component: SliderDisplay
            }
        ]
    }
];
export default sliderRoutes;

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import RouterView from "@/components/common/RouterView.vue";
import Layout from "@/components/Template/Layout.vue";
import adminRoutes from "@/router/adminPanel/index";
import faqRoutes from "@/router/faq/index";
import cmsRoutes from "@/router/cms/index";
import sliderRoutes from "@/router/slider/index";
import deviceRoutes from "@/router/device/index";
import enterRoutes from "@/router/enter/index";
import settingsRoutes from "@/router/settings/index";
import customerRoutes from "@/router/customer/index";
import orderRoutes from "@/router/order/index";
import { RouteNames } from "@/router/routes";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: RouteNames.ROOT,
        component: Layout,
        redirect: { name: RouteNames.HOME },
        children: [
            {
                path: "home",
                name: RouteNames.HOME,
                component: RouterView,
                redirect: { name: RouteNames.HOME_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.HOME_LIST,
                        component: Home,
                    },
                    {
                        path: ":path",
                        name: RouteNames.HOME_DETAIL,
                        component: Home,
                    }
                ]
            },
            ...faqRoutes,
            ...adminRoutes,
            ...deviceRoutes,
            ...sliderRoutes,
            ...cmsRoutes,
            ...settingsRoutes,
            ...customerRoutes,
            ...orderRoutes
        ]
    },
    ...enterRoutes
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
export default router;

import axios from "axios";
class ApiOffer {
    static async GetOfferByUUID(session_uuid, offer_uuid) {
        try {
            const result = await axios.post("/api/shop/offer/get-offer-by", {
                offer_uuid: offer_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
}
export default ApiOffer;

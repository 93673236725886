import UtilEmail from "@/util/Common/UtilEmail";
import UtilSex from "@/util/Common/UtilSex";
class UtilFormValidation {
    static CreateOnMaxValueLengthRules(maxLength) {
        return [(value) => value.length <= maxLength || `Length not be ${maxLength}`];
    }
    static CreateOnMinValueLengthRules(minLength, allow_empty) {
        return [(value) => value.length >= minLength || (allow_empty && value.length == 0) || `Length must be not small then ${minLength}`];
    }
    static OnValidationExactNumber(length) {
        return [(value) => (value.length == length && /^\d+$/.test(value)) || `Length must be ${length}`];
    }
    static OnValidationSourceFileAnchorField() {
        return [(value) => (/[^0-9A-Za-z-_]/.test(value)) || `Field is required`];
    }
    static OnValidationSimpleType(type) {
        return [(value) => type == typeof value || `Value does not belong to a type ${type}`];
    }
    static OnValidationArrayType(type) {
        return [(value) => value && value.split(',').every(x => typeof x === type) || `Value does not belong to a type ${type}`];
    }
}
UtilFormValidation.OnValidationEmailRules = [(value) => value.length == 0 || UtilEmail.CanValidEmail(value) || "Not correct email"];
UtilFormValidation.OnValidationSexRules = [(value) => UtilSex.CanValidSex(value, true) || "Не корретный пол"];
UtilFormValidation.OnValidationPasswordRules = [(value) => value.length >= 6 || "Password length must not less 6", (value) => value.length <= 24 || "Password length must be 24"];
UtilFormValidation.OnValidationInn = [(value) => value.length == 10 || (value.length == 12 && /^\d+$/.test(value)) || "Введите корректный ИНН"];
UtilFormValidation.OnValidationOgrn = [(value) => value.length == 13 || (value.length == 15 && /^\d+$/.test(value)) || "Введите корректный ОГРН/ОГРНИП"];
export default UtilFormValidation;

import UtilFormValidation from "@/util/Form/UtilFormValidation";
import EUserEnum from "@/struct/user/EUserEnum";
import i18n from '@/i18n';
import { RouteNames } from "@/router/routes";
class DataUserEditProfilePage {
    static ClearFormModel() {
        for (const formItem in DataUserEditProfilePage.FormModel) {
            DataUserEditProfilePage.FormModel[formItem].value = DataUserEditProfilePage.FormModel[formItem].default;
        }
    }
}
DataUserEditProfilePage.OnValidationConfirmEmailRules = [(value) => value != DataUserEditProfilePage.FormModel.EmailRepeat.value || "Email не совпадает"];
DataUserEditProfilePage.OnValidationConfirmPasswordRules = [() => DataUserEditProfilePage.FormModel.Password.value != DataUserEditProfilePage.FormModel.PasswordRepeat.value || "Пароли не совпадают"];
DataUserEditProfilePage.DefaultKeyFromLeftMenu = "profile";
DataUserEditProfilePage.DeleteKeyFromLeftMenu = "delete";
DataUserEditProfilePage.LeftMenuItems = [
    {
        icon: "fas fa-user-alt",
        label: i18n.t("user_management.user_profile"),
        key: DataUserEditProfilePage.DefaultKeyFromLeftMenu,
    },
    {
        icon: "fas fa-lock",
        label: i18n.t("user_management.change_password"),
        key: "password",
    },
    {
        icon: "fas fa-envelope",
        label: i18n.t("user_management.change_email"),
        key: "email",
    },
    {
        icon: "fas fa-question",
        label: i18n.t("user_management.change_status"),
        key: "change-status",
    },
    {
        icon: "fas fa-user-tag",
        label: i18n.t("user_management.change_role"),
        key: "change-role",
    },
    {
        icon: "fas fa-trash",
        label: i18n.t("user_management.delete_profile"),
        key: DataUserEditProfilePage.DeleteKeyFromLeftMenu,
    },
];
DataUserEditProfilePage.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.ADMIN_USERS },
        text: i18n.t('breadcrumbs.user_management'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.edit')
    }
];
DataUserEditProfilePage.FormModel = {
    Email: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 40,
        rules: [...UtilFormValidation.OnValidationEmailRules, ...UtilFormValidation.CreateOnMaxValueLengthRules(40)],
    },
    EmailRepeat: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 40,
        rules: [...UtilFormValidation.OnValidationEmailRules, ...UtilFormValidation.CreateOnMaxValueLengthRules(40)],
    },
    OldPassword: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
    Password: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
    PasswordRepeat: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
};
DataUserEditProfilePage.FormUserData = {
    Name: {
        name: i18n.t('user_management.first_name'),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(2, true), ...UtilFormValidation.CreateOnMaxValueLengthRules(24)],
    },
    LastName: {
        name: i18n.t('user_management.last_name'),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(2, true), ...UtilFormValidation.CreateOnMaxValueLengthRules(24)],
    },
    Phone: {
        name: i18n.t('user_management.phone_number'),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 15,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(10, true), ...UtilFormValidation.CreateOnMaxValueLengthRules(15)],
    },
};
DataUserEditProfilePage.FormUserEmail = {
    Email: {
        name: i18n.t('user_management.email_user'),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 40,
        rules: [...UtilFormValidation.OnValidationEmailRules, ...UtilFormValidation.CreateOnMaxValueLengthRules(40)],
    },
};
DataUserEditProfilePage.FormUserPassword = {
    Password: {
        name: i18n.t('user_management.new_password'),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
    PasswordRepeat: {
        name: i18n.t('user_management.password_repeat'),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
};
DataUserEditProfilePage.FormChangeStatus = {
    Status: {
        name: i18n.t('user_management.change_of_status'),
        message: "",
        value: "",
        default: "",
        type: "select",
        maxlength: 20,
        select: [
            {
                label: i18n.t('user_management.active'),
                value: EUserEnum.UserStatus.Active
            },
            {
                label: i18n.t('user_management.no_active'),
                value: EUserEnum.UserStatus.NoActive
            }
        ],
        rules: []
    }
};
DataUserEditProfilePage.FormChangeRole = {
    Role: {
        name: i18n.t('user_management.role_change'),
        message: "",
        value: "",
        default: "",
        type: "select",
        maxlength: 20,
        select: [
            {
                label: i18n.t('user_management.call_center_agent'),
                value: EUserEnum.SystemRole.NoRole
            },
            {
                label: i18n.t('user_management.chief_agent'),
                value: EUserEnum.SystemRole.ChiefAgent
            },
            {
                label: i18n.t('user_management.administrator'),
                value: EUserEnum.SystemRole.Admin
            },
            {
                label: i18n.t('user_management.super_administrator'),
                value: EUserEnum.SystemRole.SuperAdmin
            },
        ],
        rules: []
    }
};
export default DataUserEditProfilePage;

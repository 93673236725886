import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataSupportFiles {
}
DataSupportFiles.FilesListBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms')
    },
    {
        to: { name: RouteNames.CMS_FILES },
        exact: true,
        text: i18n.t('breadcrumbs.support_files')
    },
    {
        text: i18n.t('breadcrumbs.files'),
        disabled: true
    },
];
DataSupportFiles.FilesListCreateBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms')
    },
    {
        to: { name: RouteNames.CMS_FILES },
        text: i18n.t('breadcrumbs.support_files')
    },
    {
        text: i18n.t('breadcrumbs.files'),
        to: { name: RouteNames.CMS_FILES },
    },
    {
        text: i18n.t('breadcrumbs.create'),
        disabled: true
    }
];
DataSupportFiles.FilesListEditBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms')
    },
    {
        to: { name: RouteNames.CMS_FILES },
        text: i18n.t('breadcrumbs.support_files')
    },
    {
        text: i18n.t('breadcrumbs.files'),
        to: { name: RouteNames.CMS_FILES },
    },
    {
        text: i18n.t('breadcrumbs.edit'),
        disabled: true
    }
];
DataSupportFiles.FilesListTableHeaders = [
    {
        text: i18n.t('table_headers.sorting'),
        value: "priority",
        width: '10%'
    },
    {
        text: i18n.t('table_headers.file_name'),
        value: "name"
    },
    {
        text: i18n.t('table_headers.category'),
        value: "category"
    },
    {
        text: i18n.t('table_headers.action'),
        width: '15%',
        sortable: false,
        value: "action"
    }
];
DataSupportFiles.CategoriesTableHeaders = [
    {
        text: i18n.t('table_headers.sorting'),
        value: "priority",
        width: '10%'
    },
    {
        text: i18n.t('table_headers.category_name'),
        value: "name",
        width: '60%'
    },
    {
        text: i18n.t('table_headers.action'),
        value: "action"
    }
];

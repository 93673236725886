import UtilFormValidation from "@/util/Form/UtilFormValidation";
import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataBlog {
}
DataBlog.BlogInfoModel = {
    Title: {
        name: i18n.t("table_headers.title"),
        message: "Title of article",
        value: "",
        default: "",
        type: "text",
        maxlength: 128
    },
    Image: {
        name: i18n.t("table_headers.image"),
        message: "Image of article",
        value: "",
        default: "",
        type: "text",
        maxlength: 256,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(2), ...UtilFormValidation.CreateOnMaxValueLengthRules(256)],
    },
    IsShow: {
        name: i18n.t("table_headers.is_show"),
        message: "",
        value: "",
        default: "",
        type: "select",
        maxlength: 20,
        select: [
            {
                label: 'True',
                value: 'true'
            },
            {
                label: 'False',
                value: 'false'
            },
        ],
        rules: []
    }
};
DataBlog.BlogListBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        text: i18n.t('blog.blog'),
        disabled: true
    }
];
DataBlog.BlogCreateBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        text: i18n.t('blog.blog'),
        to: { name: RouteNames.ADMIN_BLOG },
    },
    {
        text: i18n.t('breadcrumbs.create'),
        disabled: true
    }
];
DataBlog.BlogEditBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        text: i18n.t('blog.blog'),
        to: { name: RouteNames.ADMIN_BLOG },
    },
    {
        text: i18n.t('breadcrumbs.edit'),
        disabled: true
    }
];
DataBlog.TableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "create_at",
        width: '15%',
        sortable: false
    },
    {
        text: i18n.t("table_headers.name"),
        value: "title"
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
        sortable: false,
        width: '15%'
    }
];
export default DataBlog;

import FilesList from "@/views/CMS/Files/FilesList.vue";
import FilesEdit from "@/views/CMS/Files/FilesEdit.vue";
import FilesCategories from "@/views/CMS/Files/FilesCategories.vue";
import { RouteNames } from "@/router/routes";
import SourceFiles from "@/views/CMS/SourceFiles/SourceFiles.vue";
import RouterView from "@/components/common/RouterView.vue";
import Variables from "@/views/CMS/Variables/Variables.vue";
import FeedbackContacts from "@/views/CMS/Feedback/FeedbackContacts.vue";
import FeedbackPartners from "@/views/CMS/Feedback/FeedbackPartners.vue";
const cmsRoutes = [
    {
        path: "cms",
        name: RouteNames.CMS,
        component: RouterView,
        children: [
            {
                path: "files",
                name: RouteNames.CMS_FILES,
                component: RouterView,
                redirect: { name: RouteNames.CMS_FILES_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.CMS_FILES_LIST,
                        component: FilesList
                    },
                    {
                        path: "create",
                        name: RouteNames.CMS_FILES_CREATE,
                        component: FilesEdit
                    },
                    {
                        path: "edit/:uuid",
                        name: RouteNames.CMS_FILES_EDIT,
                        component: FilesEdit
                    },
                    {
                        path: "categories",
                        name: RouteNames.CMS_FILES_CATEGORIES,
                        component: FilesCategories
                    }
                ]
            },
            {
                path: "source-files",
                name: RouteNames.CMS_SOURCE_FILES,
                component: SourceFiles,
            },
            {
                path: "variables",
                name: RouteNames.CMS_VARIABLES,
                component: RouterView,
                redirect: { name: RouteNames.CMS_VARIABLES_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.CMS_VARIABLES_LIST,
                        component: Variables,
                    }
                ]
            },
            {
                path: "feedback",
                name: RouteNames.CMS_FEEDBACK,
                component: RouterView,
                redirect: { name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST },
                children: [
                    {
                        path: "contacts",
                        name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST,
                        component: FeedbackContacts
                    },
                    {
                        path: "partners",
                        name: RouteNames.CMS_FEEDBACK_PARTNERS_LIST,
                        component: FeedbackPartners
                    }
                ]
            }
        ]
    }
];
export default cmsRoutes;

import axios from "axios";
export default class ApiCustomer {
    static async GetCustomerInformation(session_uuid) {
        try {
            const result = await axios.get("/api/admin/customer/list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get customers");
        }
    }
    static async GetCustomerInformationByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get(`/api/admin/customer/get?uuid=${uuid}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get customer");
        }
    }
}

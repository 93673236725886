import FaqQuestions from "@/views/Faq/FaqQuestions.vue";
import RouterView from "@/components/common/RouterView.vue";
import FaqCategories from "@/views/Faq/FaqCategories.vue";
import EditQuestion from "@/views/Faq/EditQuestion.vue";
import { RouteNames } from "@/router/routes";
const faqRoutes = [
    {
        path: "faq",
        name: RouteNames.FAQ,
        component: RouterView,
        redirect: { name: RouteNames.FAQ_QUESTIONS },
        children: [
            {
                path: "questions",
                name: RouteNames.FAQ_QUESTIONS,
                component: FaqQuestions
            },
            {
                path: "categories",
                name: RouteNames.FAQ_QUESTIONS_CATEGORIES,
                component: FaqCategories
            },
            {
                path: "create",
                name: RouteNames.FAQ_QUESTIONS_CREATE,
                component: EditQuestion
            },
            {
                path: "edit/:uuid",
                name: RouteNames.FAQ_QUESTIONS_EDIT,
                component: EditQuestion
            }
        ]
    }
];
export default faqRoutes;

import axios from "axios";
class ApiSupportFiles {
    static async GetFiles(session_uuid) {
        try {
            const result = await axios.get("/api/cms/files/get-files", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get files");
        }
    }
    static async GetFileByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/get-file-by", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFile(session_uuid, item) {
        try {
            const result = await axios.post("/api/cms/files/create-file", {
                name: item.name,
                description: item.description,
                category: item.category,
                file: item.file,
                priority: item.priority,
                publish: item.publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFile(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/delete-file", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFilePublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/change-file-publish", {
                uuid: uuid,
                publish: publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFileInfo(session_uuid, item) {
        try {
            const result = await axios.post("/api/cms/files/change-file-info", {
                uuid: item.uuid,
                name: item.name,
                description: item.description,
                category: item.category,
                file: item.file,
                priority: item.priority,
                publish: item.publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async GetFilesCategories(session_uuid) {
        try {
            const result = await axios.get("/api/cms/files/get-files-categories", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get faq");
        }
    }
    static async GetFilesCategoryByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/get-files-category-by", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFilesCategory(session_uuid, item) {
        try {
            const result = await axios.post("/api/cms/files/create-files-category", {
                name: item.name,
                priority: item.priority,
                publish: item.publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFilesCategory(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/delete-files-category", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFilesCategoryPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/change-files-category-publish", {
                uuid: uuid,
                publish: publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFilesCategoryInfo(session_uuid, item) {
        try {
            const result = await axios.post("/api/cms/files/change-files-category-info", {
                uuid: item.uuid,
                name: item.name,
                priority: item.priority,
                publish: item.publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiSupportFiles;

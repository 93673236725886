import { RouteNames } from "@/router/routes";
import Login from "@/views/Enter/Login.vue";
import Logout from "@/views/Enter/Logout.vue";
import Registration from "@/views/Enter/Registration.vue";
import ForgotPassword from "@/views/Enter/ForgotPassword.vue";
import RouterView from "@/components/common/RouterView.vue";
const enterRoutes = [
    {
        path: "/enter",
        name: RouteNames.ENTER,
        component: RouterView,
        redirect: { name: RouteNames.ENTER_LOGIN },
        children: [
            {
                path: "login",
                name: RouteNames.ENTER_LOGIN,
                component: Login,
            },
            {
                path: "login/:email",
                name: RouteNames.ENTER_LOGIN_EMAIL,
                component: Login,
            },
            {
                path: "logout",
                name: RouteNames.ENTER_LOGOUT,
                component: Logout,
            },
            {
                path: "registration",
                name: RouteNames.ENTER_REGISTRATION,
                component: Registration,
            },
            {
                path: "forgot-password",
                name: RouteNames.ENTER_FORGOT_PASSWORD,
                component: ForgotPassword,
            }
        ]
    }
];
export default enterRoutes;

import RouterView from "@/components/common/RouterView.vue";
import { RouteNames } from "@/router/routes";
import Orders from "@/views/Order/Orders.vue";
import EditOrder from "@/views/Order/EditOrder.vue";
const orderRoutes = [
    {
        path: "order",
        name: RouteNames.ORDER,
        component: RouterView,
        redirect: { name: RouteNames.ORDER_LIST },
        children: [
            {
                path: "list",
                name: RouteNames.ORDER_LIST,
                component: Orders
            },
            {
                path: "create",
                name: RouteNames.ORDER_CREATE,
                //component: EditQuestion
            },
            {
                path: "edit/:uuid",
                name: RouteNames.ORDER_EDIT,
                component: EditOrder
            }
        ]
    }
];
export default orderRoutes;

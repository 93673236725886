import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
export default class DataAddonCategories {
}
DataAddonCategories.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.E_SHOP } },
        text: i18n.t("breadcrumbs.e_shop"),
    },
    {
        text: i18n.t("breadcrumbs.addon_categories"),
        disabled: true
    }
];
DataAddonCategories.TableHeaders = [
    {
        text: i18n.t("table_headers.name"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.created_at"),
        value: "created_at",
        sortable: false
    },
    {
        text: i18n.t("table_headers.product_type"),
        value: "product_type",
    },
    {
        text: i18n.t("table_headers.activation_price"),
        value: "activation_price",
    },
    {
        text: i18n.t("table_headers.internal_type"),
        value: "internal_type",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
        sortable: false,
        width: "15%"
    }
];

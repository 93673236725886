import Users from "@/views/AdminPanel/user/Users.vue";
import EditUser from "@/views/AdminPanel/user/EditUser.vue";
import Blog from "@/views/CMS/Blog/Blogs.vue";
import BlogEdit from "@/views/CMS/Blog/BlogEdit.vue";
import { RouteNames } from "@/router/routes";
import RouterView from "@/components/common/RouterView.vue";
import AddonsTariff from "@/views/AdminPanel/AddonsTariff.vue";
import PortfolioProjects from "@/views/AdminPanel/PortfolioProjects.vue";
const adminRoutes = [
    {
        path: "admin",
        name: RouteNames.ADMIN,
        component: RouterView,
        redirect: { name: RouteNames.ADMIN_BLOG },
        children: [
            {
                path: "users",
                name: RouteNames.ADMIN_USERS,
                component: RouterView,
                redirect: { name: RouteNames.ADMIN_USERS_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.ADMIN_USERS_LIST,
                        component: Users,
                    },
                    {
                        path: "edit-user/:user_uuid",
                        name: RouteNames.ADMIN_USERS_EDIT,
                        component: EditUser
                    },
                ]
            },
            {
                path: "blog",
                name: RouteNames.ADMIN_BLOG,
                component: RouterView,
                redirect: { name: RouteNames.ADMIN_BLOG_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.ADMIN_BLOG_LIST,
                        component: Blog,
                    },
                    {
                        path: "create",
                        name: RouteNames.ADMIN_BLOG_CREATE,
                        component: BlogEdit
                    },
                    {
                        path: "edit/:uuid",
                        name: RouteNames.ADMIN_BLOG_EDIT,
                        component: BlogEdit
                    }
                ]
            },
            {
                path: "addons",
                name: RouteNames.ADMIN_ADDONS,
                component: AddonsTariff
            },
            {
                path: "projects",
                name: RouteNames.ADMIN_PORTFOLIO_PROJECTS,
                component: PortfolioProjects
            }
        ]
    }
];
export default adminRoutes;

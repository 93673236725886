export var EAdminPanelAddonProductType;
(function (EAdminPanelAddonProductType) {
    EAdminPanelAddonProductType["POSTPAID"] = "Post paid";
    EAdminPanelAddonProductType["PREPAID"] = "Pre paid";
    EAdminPanelAddonProductType["HYBRID"] = "Hybrid";
})(EAdminPanelAddonProductType || (EAdminPanelAddonProductType = {}));
export var EAdminPanelOfferInternalType;
(function (EAdminPanelOfferInternalType) {
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["UnknownProduct"] = 0] = "UnknownProduct";
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["TelecomProduct"] = 1] = "TelecomProduct";
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["ProjectProduct"] = 2] = "ProjectProduct";
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["VillageProduct"] = 3] = "VillageProduct";
})(EAdminPanelOfferInternalType || (EAdminPanelOfferInternalType = {}));

import axios from "axios";
class ApiSettings {
    static async GetEmailNotifications(session_uuid) {
        try {
            const result = await axios.get("/api/settings/get-email-notifications", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get email notifications");
        }
    }
    static async GetEmailNotificationByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/settings/get-email-notifications-by", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateEmailNotification(session_uuid, item) {
        try {
            const result = await axios.post("/api/settings/create-email-notifications", {
                publish: item.publish,
                template_id: item.template_id,
                template_name: item.template_name,
                topic_name: item.topic_name,
                send_to: item.send_to,
                file: item.file
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateEmailNotificationPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/settings/change-email-notifications-publish", {
                uuid: uuid,
                publish: publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateEmailNotification(session_uuid, item) {
        try {
            const result = await axios.post("/api/settings/change-email-notifications-info", {
                template_name: item.template_name,
                topic_name: item.topic_name,
                send_to: item.send_to,
                file: item.file,
                publish: item.publish,
                uuid: item.uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteEmailNotification(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/settings/delete-email-notifications", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetGreenConfig(session_uuid) {
        try {
            const result = await axios.get("/api/admin/green/config", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get green config");
        }
    }
    static async UpdateGreenConfig(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/green/config", {
                key: item.key,
                value: item.value,
                type: item.type,
                description: item.description,
                details: item.details
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetApplicationSettings(session_uuid) {
        try {
            const result = await axios.get("/api/settings/get-app-settings", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get application settings");
        }
    }
    static async UpdateApplicationSettings(session_uuid, name, value) {
        try {
            const result = await axios.post("/api/settings/set-app-setting", {
                name,
                value
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.status;
        }
        catch (e) {
            return undefined;
        }
    }
}
export default ApiSettings;
